import React, { useState, useEffect } from 'react'
import { compileQuery } from '../../utils/helpers'
import { decodeEntities } from '../../utils/htmlParse'
import './CaseStudiesNavigation.scss'

const isClient = typeof window !== 'undefined'

const Options = props => {
  const { location, value, title, parent } = props
  return (
    <>
      <option value={value}>{title}</option>
      {location.map(
        ({ node: subLocation }, index) =>
          subLocation.wordpress_parent === parent && (
            <option key={index} value={subLocation.slug}>
              {' '}
              &nbsp;&nbsp; {decodeEntities(subLocation.name)}
            </option>
          )
      )}
    </>
  )
}

export const CaseStudiesNavigation = props => {
  const [filterSector, setFilterSector] = useState('')
  const [filterLocation, setFilterLocation] = useState('')
  const [filterValue, setFilterValue] = useState('')

  const getUrlVars = () => {
    var vars = [],
      hash
    var query_string = isClient && window.location.search

    if (query_string) {
      var hashes = query_string.slice(1).split('&')
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=')
        vars[hash[0]] = hash[1]
      }
      return vars
    } else {
      return false
    }
  }

  useEffect(() => {
    const urlVars = getUrlVars()
    setFilterValue(urlVars.value)
    setFilterLocation(urlVars.location)
    setFilterSector(urlVars.sector)
  }, [])

  const navigateTo = (event, reset = false) => {
    let resetVals = null
    // Decides what params to remove from the url depending on which selector is used
    // 'page' should always be removed to reset pagination back to page 1
    switch (event.target.name) {
      case 'value':
        resetVals = ['page']
        setFilterValue(event.target.value)
        break
      case 'location':
        resetVals = ['value', 'page']
        setFilterLocation(event.target.value)
        setFilterValue('')
        break
      case 'sector':
        resetVals = ['value', 'location', 'page']
        setFilterSector(event.target.value)
        setFilterValue('')
        setFilterLocation('')
        break
      default:
        resetVals = null
    }

    const newDest = `filter/${compileQuery(
      event.target.name,
      event.target.value,
      resetVals
    )}`

    //navigate(`/case-studies/${newDest}#filter`);
    // Testing redirect to new page
    window.location.href = `/case-studies/${newDest}#filter`
  }

  const clearFilter = event => {
    //navigate(`/case-studies/#filter`);
    //Testing redirect to new page
    window.location.href = `/case-studies/#filter`
  }

  const { location = '', sector = '', caseStudies, allCaseStudies } = props

  const pageAttr = getUrlVars()

  var incSectors = []
  caseStudies &&
    caseStudies.map(
      a =>
        a.node.sector &&
        a.node.sector.map(b => !incSectors.includes(b) && incSectors.push(b))
    )

  // Get case studies belonging to the selected sector,
  // or get all if no sector is selected
  const sectorCaseStudies =
    allCaseStudies &&
    allCaseStudies.edges.filter(({ node: item }) => {
      // Get the active sector slug from the url params and use to get its associated wordpress_id
      const activeSector = filterSector
        ? sector.edges.find(({ node: s }) => s.slug === filterSector)
        : ''
      // If case study is in current selected sector include it in sectorCasestudies
      return activeSector
        ? item.sector.includes(activeSector.node.wordpress_id)
        : true
    })

  // Get only locations belonging to case studies in the selected sector
  const filteredLocations = location
    ? location.edges.filter(({ node: item }) => {
        return sectorCaseStudies
          ? sectorCaseStudies.some(cs =>
              cs.node.location.includes(item.wordpress_id)
            )
          : true
      })
    : ''

  // Get case studies belonging to the selected sector and location,
  // or get all if no sector or location is selected
  const sectorLocationCaseStudies =
    sectorCaseStudies &&
    sectorCaseStudies.filter(({ node: item }) => {
      // Get the active sector's wordpress_id
      const activeLocation = filterLocation
        ? location.edges.find(({ node: l }) => l.slug === filterLocation)
        : ''
      // If case study is in current selected sector include it in sectorCasestudies
      return activeLocation
        ? item.location.includes(activeLocation.node.wordpress_id)
        : true
    })

  return (
    <section className="CaseStudiesNavigation">
      <div id="filter" />
      <span className="filter-by-label">Filter By:</span>
      <div className="select-group">
        <div className="select-container" id="selectSector">
          <select
            name="sector"
            onChange={e => navigateTo(e)}
            value={filterSector}
          >
            <option value="">Sector</option>
            {sector &&
              sector.edges.map(
                ({ node: item }, index) =>
                  item.wordpress_parent === 0 && (
                    <option key={index} value={item.slug}>
                      {decodeEntities(item.name)}
                    </option>
                  )
              )}
          </select>
        </div>
        <div className="select-container notranslate" id="selectLocation">
          <select
            name="location"
            onChange={e => navigateTo(e)}
            value={filterLocation}
          >
            <option value="">Location</option>
            {location &&
              filteredLocations &&
              filteredLocations.map(
                ({ node: item }, index) =>
                  item.wordpress_parent === 0 && (
                    <Options
                      key={index}
                      parent={item.wordpress_id}
                      location={filteredLocations}
                      value={item.slug}
                      title={decodeEntities(item.name)}
                    />
                  )
              )}
          </select>
        </div>
        {sector &&
          sector.edges.map(
            ({ node: itema }, index) =>
              itema.wordpress_parent === 0 &&
              pageAttr &&
              pageAttr.sector === itema.slug && (
                <div
                  className="select-container notranslate"
                  key={index}
                  id="selectValue"
                >
                  <select
                    name="value"
                    onChange={e => navigateTo(e)}
                    value={filterValue}
                  >
                    <option value="">{`All ${itema.name}`}</option>
                    {sector &&
                      sector.edges
                        .filter(({ node: item }) =>
                          sectorLocationCaseStudies.some(
                            ({ node: caseStudy }) =>
                              caseStudy.sector.includes(item.wordpress_id)
                          )
                        )
                        .map(
                          ({ node: item }, index) =>
                            item.wordpress_parent === itema.wordpress_id && (
                              <Options
                                key={index}
                                parent={item.wordpress_id}
                                location={sectorLocationCaseStudies}
                                value={item.slug}
                                title={decodeEntities(item.name)}
                              />
                            )
                        )}
                  </select>
                </div>
              )
          )}
      </div>
      {pageAttr && (
        <div className="clear-filter">
          <button onClick={e => clearFilter(e)}>Clear Filter</button>
        </div>
      )}
    </section>
  )
}
