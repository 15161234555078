import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { CaseStudiesList } from '../components/CaseStudies/CaseStudiesList'
import { CaseStudiesNavigation } from '../components/CaseStudies/CaseStudiesNavigation'
import Pagination from '../components/Pagination'
import { Hero } from '../acf';

const IndexPage = (props) => {
  const { data, pageContext, location } = props;

  const {
    page,
    caseStudies,
    sector,
    location: locationData
  } = data;

  const {
    pageTitle,
    yoast,
    acf
  } = page;

  const {
    subheading,
    heading,
    blurb,
    backgroundImage,
    isCaseStudy
  } = acf.layout[0];

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <SEO
        title={pageTitle}
        desc={yoast.metaDescription}
      />
      <Hero subheading={subheading} heading={heading} blurb={blurb} backgroundImage={backgroundImage} />
      <div className="case-studies-list-container">
        <div className="wrapper">
          <CaseStudiesNavigation
            location={locationData}
            sector={sector}
            caseStudies={caseStudies.edges}
          />
          <CaseStudiesList
            location={locationData}
            sector={sector}
            caseStudies={caseStudies.edges}
          />
          <Pagination pageContext={pageContext} pathPrefix={`/case-studies`} pathSuffix={`#filter`} />
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
  query CaseStudiesPageQuery($limit: Int!, $skip: Int!) {
    page: wordpressPage(slug: {eq: "case-studies"}) {
      yoast {
        metadesc
        title
      }
      pageTitle: title
      acf {
        layout: layout_page {
          __typename,
          ... on WordPressAcf_Hero {
            id
            subheading
            heading
            blurb
            backgroundImage {
              source_url
            }
          }
        }
      }
    }
    caseStudies: allWordpressWpCaseStudies (
      limit: $limit
      skip: $skip
      sort: {fields: menu_order, order: ASC}
      ) {
      edges {
        node {
          slug
          title
          location
          sector
          acf {
            summary
            isFeatured
          }
          featuredImage: featured_image_url {
            source_url
          }
        }
      }
    }
    location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          wordpress_id
          wordpress_parent
        }
      }
    }
    sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
